import { Command, CommandExecutionContext } from '../Command';

export class PopText extends Command {

    public readonly text: string;

    public execute(context: CommandExecutionContext): void {
        context.animation.texts.pop();
    }
}
