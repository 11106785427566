import { Command, CommandExecutionContext } from '../Command';

export class SetTitle extends Command {

    public text: string;

    public execute(context: CommandExecutionContext): void {
        context.animation.title = this.text;
    }

}
