import { Command, CommandExecutionContext } from '../Command';

export class ClearText extends Command {

    public execute(context: CommandExecutionContext): void {
        while (context.animation.texts.length > 0) {
            context.animation.texts.pop();
        }
    }
}
