import { route } from '@lernetz/ts-lib/src/Setup';

import { Command, CommandExecutionContext } from '../Command';

import { Audio } from '../../audio/Audio';

export class StartAudio extends Command {

    public readonly audio: Audio;

    public execute(context: CommandExecutionContext): void {
        const audio = context.animation.audio;
        audio.src = route('audio', { file_name: this.audio.file_name }).url();
        audio.play();
    }
}
