import { Model } from '@lernetz/ts-lib';

import { Animation } from './Animation';
import { Command } from './Command';

export interface ScriptExecutionContext {
    readonly animation: Animation;
}

export class Script extends Model {

    public readonly commands: Command[];
    public readonly key: string;

    private ticker: ReturnType<typeof setInterval>;

    public constructor(dto) {
        super(dto);
    }

    public abort(): void {
        clearInterval(this.ticker);
        this.ticker = null;
    }

    public execute(context: ScriptExecutionContext): void {
        let timeIndex = 0;
        this.ticker = setInterval(() => {
            const time = ++timeIndex / 10;
            this.onTime(time, context);
        }, 100);
        this.onTime(0, context);
    }

    public onTime(time: number, context: ScriptExecutionContext) {
        const commands = this.commands.filter(command => Math.abs(command.time - time) < 0.05);
        for (const command of commands) {
            console.log('executing command', command, 'at time', time);
            command.execute({ ...context, script: this });
        }
    }
}
