import { Command, CommandExecutionContext } from '../Command';

export class ResumeAnimation extends Command {

    public readonly reverse: boolean;


    public execute(context: CommandExecutionContext): void {
        context.animation.postMessageForAnimation({
            'command': 'play',
            'reverse': this.reverse,
        });
    }
}
