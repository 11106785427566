import { View } from '@lernetz/ts-lib';

import template from './Text.html';

export class Text {

    public constructor(public readonly text: string) {
    }
}

export class TextView extends View<Text> {

    public constructor() {
        super({ template });
    }

    init() {
        const p = this.node.querySelector('[js-p]');
        p.innerHTML = this.data.text;
        addContentItemAndTextStyleVariants(p);
    }
}

function addContentItemAndTextStyleVariants(root: Node): void {
    const walker = document.createTreeWalker(root, NodeFilter.SHOW_ELEMENT);
    while (walker.nextNode()) {
        const node = <Element> walker.currentNode;
        node.classList.add('text-style');
        switch (node.localName) {
            case 'a':
                node.classList.add('-link');
                break;
            case 'b':
                node.classList.add('-em');
                break;
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
                node.classList.add(`-${node.localName}`);
                break;
            case 'li':
                node.classList.add('-para', '-list-item');
                break;
            case 'ol':
            case 'ul':
                node.classList.add('-list', '-list');
                break;
            case 'p':
                node.classList.add('-para', '-text');
                break;
        }
    }
}
