import { Command, CommandExecutionContext } from '../Command';

import { Text } from '../text/Text';

export class PushText extends Command {

    public readonly text: string;

    public execute(context: CommandExecutionContext): void {
        context.animation.texts.push(new Text(this.text));
    }
}
