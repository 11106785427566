import { Command, CommandExecutionContext } from '../Command';

export class SetNavigation extends Command {

    public allowReplayAudio: boolean;
    public previousKey: string;
    public nextKey: string;

    public execute(context: CommandExecutionContext): void {
        context.animation.navigation.allowReplayAudio = this.allowReplayAudio;
        context.animation.navigation.previousKey = this.previousKey;
        context.animation.navigation.nextKey = this.nextKey;
    }

}
