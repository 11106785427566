import { Model, View } from '@lernetz/ts-lib';
import { observeProperties } from '@lernetz/ts-lib/src/Observer';

import { Animation } from '../Animation';

import template from './Navigation.html';

export class Navigation extends Model {

    public allowReplayAudio: boolean;
    public nextKey: string;
    public previousKey: string;

    public constructor(private readonly animation: Animation) {
        super();
    }

    public executeNextScript() {
        this.animation.onMessage({ 'command': 'execute_script', 'key': this.nextKey });
    }

    public executePreviousScript() {
        this.animation.onMessage({ 'command': 'execute_script', 'key': this.previousKey });
    }

    public replayAudio() {
        this.animation.replayAudio();
    }
}

export class NavigationView extends View<Navigation> {

    public constructor() {
        super({ template });
    }
}
