import { Model } from '@lernetz/ts-lib';

export class Audio extends Model {

    public readonly ext: string;
    public readonly file_name: string;
    public readonly id: string;
    public readonly name: string;

    public constructor(dto) {
        super(dto);
    }
}
