import { Model } from '@lernetz/ts-lib';

import { Animation } from './Animation';
import { Script } from './Script';

export interface CommandExecutionContext {
    readonly animation: Animation;
    readonly script: Script;
}

export abstract class Command extends Model {

    readonly time: number;

    public constructor(dto) {
        super(dto);

        this.time = Number(dto.time || 0);
    }

    public abstract execute(context: CommandExecutionContext): void;
}
