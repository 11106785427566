import { ViewRegistry, ModelRegistry } from '@lernetz/ts-lib';
import { Repeat as RepeatView } from '@lernetz/ts-lib/src/components/Repeat';
import { Show as ShowView } from '@lernetz/ts-lib/src/components/Show';

import { Animation, AnimationView } from './animation/Animation';
import { Audio } from './audio/Audio';
import { Navigation, NavigationView } from './animation/navigation/Navigation';
import { Script } from './animation/Script';
import { Text, TextView } from './animation/text/Text';

import { ClearText } from './animation/commands/ClearText';
import { PopText } from './animation/commands/PopText';
import { PushText } from './animation/commands/PushText';
import { ResumeAnimation } from './animation/commands/ResumeAnimation';
import { ResumeAudio } from './animation/commands/ResumeAudio';
import { SetAnimationPosition } from './animation/commands/SetAnimationPosition';
import { SetNavigation } from './animation/commands/SetNavigation';
import { SetTitle } from './animation/commands/SetTitle';
import { StartAudio } from './animation/commands/StartAudio';
import { StopAnimation } from './animation/commands/StopAnimation';
import { StopAudio } from './animation/commands/StopAudio';

export var modelRegistry = new ModelRegistry();
modelRegistry.add('App\\Animation', dto => new Animation(dto));
modelRegistry.add('App\\Audio', dto => new Audio(dto));
modelRegistry.add('App\\Script', dto => new Script(dto));
modelRegistry.add('App\\Commands\\ClearText', dto => new ClearText(dto));
modelRegistry.add('App\\Commands\\PopText', dto => new PopText(dto));
modelRegistry.add('App\\Commands\\PushText', dto => new PushText(dto));
modelRegistry.add('App\\Commands\\ResumeAnimation', dto => new ResumeAnimation(dto));
modelRegistry.add('App\\Commands\\ResumeAudio', dto => new ResumeAudio(dto));
modelRegistry.add('App\\Commands\\SetAnimationPosition', dto => new SetAnimationPosition(dto));
modelRegistry.add('App\\Commands\\SetNavigation', dto => new SetNavigation(dto));
modelRegistry.add('App\\Commands\\SetTitle', dto => new SetTitle(dto));
modelRegistry.add('App\\Commands\\StartAudio', dto => new StartAudio(dto));
modelRegistry.add('App\\Commands\\StopAnimation', dto => new StopAnimation(dto));
modelRegistry.add('App\\Commands\\StopAudio', dto => new StopAudio(dto));

export var viewRegistry = new ViewRegistry();
viewRegistry.add('repeat', () => new RepeatView());
viewRegistry.add('show', () => new ShowView());
viewRegistry.add(Navigation, () => new NavigationView());
viewRegistry.add(Animation, () => new AnimationView);
viewRegistry.add(Text, () => new TextView());
