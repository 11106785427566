import { setup, scan } from '@lernetz/ts-lib';
import { route } from '@lernetz/ts-lib/src/Setup';
import { modelRegistry, viewRegistry } from './register';

export async function init(config) {
    setup(config, modelRegistry);

    if('animation' in config.data) {
        scan({ root: document.body, registry: viewRegistry, data: config.data });
    }
    else {
        init(await route('config').load());
    }
}
