import { Model, View } from '@lernetz/ts-lib';

import { Navigation } from './navigation/Navigation';
import { Script } from './Script';
import template from './Animation.html';

export class Animation extends Model {

    public readonly scripts: { [key: string]: Script };
    public readonly startScript: string;
    public readonly navigation: Navigation;
    public readonly texts: object[];
    public readonly url: string;

    public animationWindow: Window;
    public audio: HTMLAudioElement;
    public title: string;

    public constructor(dto) {
        super({
            texts: [],
            title: dto.title,
        });

        this.navigation = new Navigation(this);

        this.scripts = {};
        for (const script of dto.scripts) {
            this.scripts[script.key] = script;
        }

        this.startScript = dto.startScript || 'start';

        this.url = dto.url;
    }

    public postMessageForAnimation(message: object): void {
        console.info('sending a message', message, 'to animation window', this.animationWindow);
        this.animationWindow.postMessage(message, window.origin);
    }

    public onMessage(message: object): void {
        console.info('received message', message);
        if (typeof message === 'object' && 'command' in message) {
            switch (message['command']) {
                case 'start': {
                    if (this.startScript) {
                        this.onMessage({ 'command': 'execute_script', 'key': this.startScript });
                    }
                    break;
                }

                case 'execute_script': {
                    const key = message['key'];
                    if (key in this.scripts) {
                        console.log('executing script', key);
                        for (const key in this.scripts) {
                            this.scripts[key].abort();
                        }
                        this.scripts[key].execute({ animation: this });
                    }
                    else {
                        console.error('script', key, 'not found');
                    }
                }
            }
        }
    }

    public replayAudio(): void {
        if (this.audio) {
            this.audio.load();
            this.audio.play();
        }
    }
}

export class AnimationView extends View<Animation> {

    public constructor() {
        super({ template });
    }

    init() {
        window.addEventListener('message', e => this.data.onMessage(e.data));
        const iframe = <HTMLIFrameElement> this.node.querySelector('[js-iframe]');
        this.data.animationWindow = iframe.contentWindow;
        this.data.audio = this.node.querySelector('[js-audio]');
    }
}
