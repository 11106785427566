import { Command, CommandExecutionContext } from '../Command';

export class SetAnimationPosition extends Command {

    public key: string;

    public execute(context: CommandExecutionContext): void {
        context.animation.postMessageForAnimation({
            'command': 'goto',
            'key': this.key,
        });
    }

}
