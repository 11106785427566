import { Command, CommandExecutionContext } from '../Command';

export class StopAnimation extends Command {

    public execute(context: CommandExecutionContext): void {
        context.animation.postMessageForAnimation({
            'command': 'stop',
        });
    }
}
